import React, { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { DEFAULT_LANG, LocalStorageKey, ManualRouterUrl, RouteUrl } from './models/constants';
import './App.css';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import './assets/css/main.css';
import './assets/css/style.css';
import { useTranslation } from 'react-i18next';
import {ToastContainer} from "react-toastify";

if (typeof window !== "undefined") {
	// require("bootstrap/dist/js/bootstrap.bundle.min");
    require("bootstrap/dist/js/bootstrap.min.js");
}

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
)

const Login = React.lazy(() => import('./pages/login'));
const GeneralSetting = React.lazy(() => import('./pages/general-setting'));
const SymbolSetting = React.lazy(() => import('./pages/symbol-setting'));
const UserOnline = React.lazy(() => import('./pages/user-online'));
const UserHistory = React.lazy(() => import('./pages/user-history'));
const OrderHistory = React.lazy(() => import('./pages/order-history'));
const OperatorManagement = React.lazy(() => import('./pages/operator-management'));
const OperatorGroup = React.lazy(() => import('./pages/operator-group'));
const AuditHistory = React.lazy(() => import('./pages/audit-history'));
const Manual = React.lazy(() => import('./pages/manual'));
const Layout = React.lazy(() => import('./components/manual/layout'));
const ManualAuth = React.lazy(() => import('./components/manual/auth'));
const ManualPayoutSymbol = React.lazy(() => import('./components/manual/payout-symbol'));
const ManualUserManagement = React.lazy(() => import('./components/manual/user-management'));
const ManualMaintenance = React.lazy(() => import('./components/manual/maintenance'));
const ManualOperatorManagement = React.lazy(() => import('./components/manual/operator-management'));
const ManualOrderControl = React.lazy(() => import('./components/manual/order-control'));
const ManualBotControl = React.lazy(() => import('./components/manual/bot-control'));
const ManualAuditHistory = React.lazy(() => import('./components/manual/audit-history'));
const MaintenanceSetting = React.lazy(() => import('./pages/maintenance-setting'));
const UserGroup = React.lazy(() => import('./pages/user-group'));
const UserList = React.lazy(() => import('./pages/user-list'));
const BotControl = React.lazy(() => import('./pages/bot-control'));

const RouterDom = () => (
	<React.Suspense fallback={loading}>
		<Routes>
			<Route path="/" element={<Navigate to={RouteUrl.LOGIN} />} />
			<Route path={RouteUrl.LOGIN} element={<Login />} />
			<Route path={RouteUrl.GENERAL_SETTING} element={<GeneralSetting />} />
			<Route path={RouteUrl.SYMBOL_SETTING} element={<SymbolSetting />} />
			<Route path={RouteUrl.USER_ONLINE} element={<UserOnline />} />
			<Route path={RouteUrl.USER_HISTORY} element={<UserHistory />} />
            <Route path={RouteUrl.ORDER_HISTORY} element={<OrderHistory />} />
            <Route path={RouteUrl.USER_GROUP} element={<UserGroup />} />
            <Route path={RouteUrl.USER_LIST} element={<UserList />} />
            <Route path={RouteUrl.BOT_CONTROL} element={<BotControl />} />
			<Route path={RouteUrl.MAINTENANCE} element={<MaintenanceSetting />} />
            <Route path={RouteUrl.OPERATOR_LIST} element={<OperatorManagement />} />
            <Route path={RouteUrl.GROUP_SETTING} element={<OperatorGroup />} />
            <Route path={RouteUrl.AUDIT_HISTORY} element={<AuditHistory />} />
            <Route path={RouteUrl.MANUAL} element={<Manual/>} />
            <Route path={RouteUrl.MANUAL} element={<Layout />}>
                <Route path={ManualRouterUrl.AUTH} element={<ManualAuth />} />
                <Route path={ManualRouterUrl.PAYOUT_SETTINGS} element={<ManualPayoutSymbol />} />
                <Route path={ManualRouterUrl.USER_MANAGEMENT} element={<ManualUserManagement />} />
                <Route path={ManualRouterUrl.BOT_CONTROL} element={<ManualBotControl />} />
                <Route path={ManualRouterUrl.MAINTENANCE} element={<ManualMaintenance />} />
                <Route path={ManualRouterUrl.OPERATOR_MANAGEMENT} element={<ManualOperatorManagement />} />
                <Route path={ManualRouterUrl.ORDER_CONTROL} element={<ManualOrderControl />} />
                <Route path={ManualRouterUrl.AUDIT_HISTORY} element={<ManualAuditHistory />} />
            </Route>
		</Routes>
	</React.Suspense>
)

const App = () => {
	const { i18n } = useTranslation();

	let currentLanguage = DEFAULT_LANG;
	if (localStorage.getItem(LocalStorageKey.LANGUAGE))
		currentLanguage = localStorage.getItem(LocalStorageKey.LANGUAGE) as string;

	const [language, setLanguage] = useState(currentLanguage);

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [language])

	return (
        <BrowserRouter>
			<RouterDom/>
            <ToastContainer theme="colored"/>
        </BrowserRouter>
    )
}

export default App;