import React from "react";

export default {
    translation: {
        "title": "Bullbear Admin",
        "signIn": "Sign In",
        "loginId": "ID",
        "password": "Password",
        "loginBtn": "Login",
        "login": "Login",
        "updateBtn": "Update",
        "generalSetting": "Payout Configuration",
        "symbolSetting": "Symbol Configuration",
        "logout": "Logout",
        "smoothness": "Smoothness",
        "mathematics": "Mathematics",
        "logisticBasis": "Logistic Basis",
        "logisticBasisValue": "Logistic Basis Valuable",
        "symbol": "Symbol",
        "spreadBalance": "Spread Balance",
        "maxPayout": "Max Payout",
        "minPayout": "Min Payout",
        "digits": "Digits",
        "loginFail": "Login Failed",
        "fieldRequired": "This field is required.",
        "updateSettingSuccess": "Update Setting Success",
        "updateSettingFail": "Update Setting Fail",
        "payoutInvalid": "Rule: 1 <= Payout <= 2",
        "digitInvalid": "Rule: 0 <= Digits <= 5",
        "maxPayoutInvalid": "Rule: Min Payout < Max Payout",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "confirmText": "Are you sure to change values as below",
        "smoothnessChange": "- Smoothness from <bold>{{from}}</bold> to <bold>{{to}}</bold>",
        "mathematicsChange": "- Mathematics from <bold>{{from}}</bold> to <bold>{{to}}</bold>",
        "logisticsBasisChange": "- Logistic Basis from <bold>{{from}}</bold> to <bold>{{to}}</bold>",
        "logisticsBasisValueChange": "- Logistic Basis Valuable from <bold>{{from}}</bold> to <bold>{{to}}</bold>",
        "symbolChange": "- Symbol <bold>{{symbol}}</bold>",
        "spreadBalanceChange": "- Spread Balance from <bold>{{from}}</bold> to <bold>{{to}}</bold>",
        "maxPayoutChange": "- Max Payout from <bold>{{from}}</bold> to <bold>{{to}}</bold>",
        "minPayoutChange": "- Min Payout from <bold>{{from}}</bold> to <bold>{{to}}</bold>",
        "digitsChange": "- Digits from <bold>{{from}}</bold> to <bold>{{to}}</bold>",
        "digitWarning": "(Digits should be matched with MT5 Setting)",
        "logisticBasisInvalid":"Rule: Logistic Basis > 0",
        "userOnline": "Online User",
        "loginHistory": "Login History",
        "maintenance": "Maintenance",
        "fromDate": "From",
        "toDate": "To",
        "status": "Status",
        "account": "Account",
        "search": "Search",
        "loggedIn": "Logged in",
        "loggedOut": "Logged out",
        "failed": "Failed",
        "accountID": "Account ID",
        "browser": "Browser",
        "operatingSystem": "Operating System",
        "all": "All",
        "searchBtn": "Search",
        "exportBtn": "Export",
        "refreshBtn": "Refresh",
        "loginTime": "Login Time",
        "maintenanceSchedule": "Maintenance Schedule Setting",
        "startTime": "Start Time",
        "endTime": "End Time",
        "applyBtn": "Apply",
        "maintenanceExecution": "Maintenance Execution (instant)",
        "maintenanceStartNow": "Maintenance Start Now",
        "maintenanceEndNow": "Maintenance End Now",
        "maintenanceHistory": "Maintenance History",
        "timeCreated": "Time Created",
        "type": "Type",
        "action": "Action",
        "yesBtn": "Yes",
        "noBtn": "No",
        "cancelSetting": "Cancel Setting",
        "confirmDeleteSchedule": "Are you sure to cancel this maintenance setting ?",
        "editSetting": "Edit Setting",
        "editBtn": "Edit",
        "cancelBtn": "Cancel",
        "noRecord": "No record.",
        "name": "Name",
        "searchByUserName": "Search by user name",
        "addOperator": "Add operator",
        "userName": "User Name",
        "group": "Group",
        "registerTime": "Register Time",
        "operatorStatus": "Operator Status",
        "resetPassword": "Reset Password",
        "enable": "Enable",
        "disable": "Disable",
        "createOperator": "Create Operator",
        "username": "Username",
        "fullName": "Full Name",
        "email": "Email",
        "submit": "Submit",
        "editOperatorInformation": "Edit Operator Information",
        "resetPasswordTitle": "Reset Operator's Password",
        "resetPasswordMessage": "Are you sure you want to  reset password for this operator ?",
        "reset": "Reset",
        "enableOperatorTitle": "Enable Operator",
        "enableOperatorMessage": "Are you sure want to enable this operator?",
        "disableOperatorTitle": "Disable Operator",
        "disableOperatorMessage": "Are you sure want to disable this operator?",
        "note": "Note",
        "groupType": {
            "cqj": "CQJ",
            "admin": "Admin",
            "manager": "Manager",
            "operator": "Operator",
            "readonly": "Readonly",
        },
        "forceLogoutSuccess": "Force Logout Success",
        "forceLogoutFail": "Force Logout Fail",
        "endTimeInvalid": "End time must greater than Start time",
        "startTimeInvalid": "Start time must greater than current time",
        "applyMaintainSuccess": "Apply Maintenance Success",
        "applyMaintainFail": "Apply Maintenance Fail",
        "startMaintainSuccess": "Start Maintenance Success",
        "startMaintainFail": "Start Maintenance Fail",
        "endMaintainSuccess": "End Maintenance Success",
        "endMaintainFail": "End Maintenance Fail",
        "deleteMaintainSuccess": "Delete Maintenance Success",
        "deleteMaintainFail": "Delete Maintenance Fail",
        "editMaintainSuccess": "Edit Maintenance Success",
        "editMaintainFail": "Edit Maintenance Fail",
        "applied": "Applied",
        "scheduled": "Scheduled",
        "canceled": "Canceled",
        "setting": "Setting",
        "instant": "Instant",
        "exportCsvFail": "Export CSV File Fail",
        "operatorGroup": "Operator Group",
        "createGroup": "Create Group",
        "id": "ID",
        "groupName": "Group Name",
        "operatorList": "Operator List",
        "editSettings": "Edit Settings",
        "deleteGroup": "Delete Group",
        "functions": "Functions",
        "read": "Read",
        "write": "Write",
        "rename": "Rename",
        "manageOperator": "Manage Operator",
        "deleteGroupMessage": "Are you sure to delete this group?",
        "no": "No",
        "yes": "Yes",
        "auditHistory": "Audit History",
        "accountManagement": "Account Management",
        "time": "Time",
        "detail": "Detail",
        "emailFormatInvalid": "Email format is invalid",
        "createUserSuccess": "Create User Success",
        "createUserFail": "Create User Fail",
        "editUserSuccess": "Edit User Success",
        "editUserFail": "Edit User Fail",
        "disableUserSuccess": "Disable User Success",
        "disableUserFail": "Disable User Fail",
        "enableUserSuccess": "Enable User Success",
        "enableUserFail": "Enable User Fail",
        "resetPasswordSuccess": "Reset Password Success",
        "resetPasswordFail": "Reset Password Fail",
        "accountDisabled": "Account is disable",
        "changePass": "Change Password",
        "currentPass": "Current password",
        "newPass": "New password",
        "confirmNewPass": "Confirm new password",
        "changePassNote": "Are you sure you want to change operator's password ?",
        "currentPassInvalid": "Current password is wrong",
        "passwordNote": "Password must be at least 8 characters in length",
        "confirmPassInvalid": "Please enter the same password as above",
        "changePasswordSuccess": "Change Password Success",
        "changePasswordFail": "Change Password Fail",
        "canNotView": "Your account can't view this web",
        "groupNameLengthError": "Group name cannot be too long",
        "groupNameBlankError": "Group name cannot be blank",
        "createGroupSuccess": "Create Group Success",
        "createGroupFail": "Create Group Fail",
        "editGroupSuccess": "Edit Group Success",
        "editGroupFail": "Edit Group Fail",
        "invalidStartTime": "Start Time must be less than To Time",
        "errorDeleteGroupMsg": "*Please clear existing users or change their groups before deleting",
        "startMaintenance": "Start Maintenance",
        "endMaintenance": "End Maintenance",
        "confirmStart": "Are you sure to run Maintenance Setting now?",
        "confirmEnd": "Are you sure to end Maintenance Setting now?",
        "function": "Function",
        "reason": "Reason",
        "timeFilterRestrictionAuditHistoryMsg1": "** Please pay attention that the search function for Audit History is only applicable within one week.",
        "timeFilterRestrictionMsg2": "If you are looking for earlier information, please contact the PIC.",
        "manual": "Manual",
        "maintenanceStartTime": "Maintenance Start Time",
        "maintenanceEndTime": "Maintenance End Time",
        "deleteGroupSuccess": "Delete Group Success",
        "userManagement": "User Management",
        "orderHistory": "Order History",
        "accountCurrencyType": "Account Currency Type",
        "orderId": "Order ID",
        "assets": "Assets",
        "duration": "Duration",
        "openingTime": "Opening Time",
        "judgementTime": "Judgement Time",
        "purchaseAmount": "Purchase Amount",
        "purchaseRate": "Purchase Rate",
        "judgementRate": "Judgement Rate",
        "payoutRatio": "Payout Ratio",
        "payoutAmount": "Payout Amount",
        "ip": "IP",
        "coordinate": "Coordinate",
        "timeFilterRestrictionOrderHistoryMsg1": "** Please pay attention that the search function for Order History is only applicable within one week.",
        "orderControl": "Order Control",
        "controlGroups": "Control Groups",
        "controlAccounts": "Control Accounts",
        "groupList": "Group List",
        "timeHold": "Time Hold",
        "holdingRate": "Holding Rate",
        "user": "User",
        "sec": "sec",
        "min": "min",
        "hour": "hour",
        "editGroup": "Edit Group",
        "remove": "Remove",
        "userID": "User ID",
        "addedTime": "Added Time",
        "addAccount": "Add Account",
        "uploadCSV": "Upload CSV",
        "payoutSetting": "Payout Setting",
        "operatorManagement": "Operator Management",
        "addUser": "Add User",
        "editAccountGroupSuccess": "Edit Account Group Success",
        "editAccountGroupFail": "Edit Account Group Fail",
        "createAccountGroupSuccess": "Create Account Group Success",
        "createAccountGroupFail": "Create Account Group Fail",
        "deleteGroupFail": "Delete Group Fail",
        "addAccountSuccess": "Add Account Success",
        "addAccountFail": "Add Account Fail",
        "removeAccountSuccess": "Remove Account Success",
        "removeAccountFail": "Remove Account Fail",
        "botControl": "Bot Control",
        "popupSetting": "Popup Setting",
        "popupMode": "Pop-up Mode",
        "timeSetting": "Time Setting",
        "botControlTitle": "Change Inactive Control Setting",
        "ok": "OK",
        "botControlMessage": "Are you sure to change inactive control setting?",
        "inputNumberOnly": "Input Number Only",
        "duplicateAccountId": "Duplicate Account Id",
        "editInactiveInfoSuccess": "Edit Inactive Info Success",
        "editInactiveInfoFail": "Edit Inactive Info Fail",
        "pluginWorkingTime": "Plugin Working Time",
        "holding": "Holding"
    }
}
